import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { ViewContainerRefService } from './components/resolveNgContent.service';
import { GraphqlService } from './graphql';
import { SelectModule } from './components/select';
import { PermissionService } from './services';
import { DownloadService } from './services/download.service';
import { AppInitializerProvider } from './app.initializer.provider';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModuleInterceptorProvider } from './interceptors/http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { FileService } from './services/file.service';
import { RefreshGridNotification } from './services/refreshGridNotification';
import { LoggerModule } from './components/logger';
import { HeaderModule } from './layout/header/header.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  exports: [SelectModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    LoggerModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [
    ...HttpClientModuleInterceptorProvider,
    ...AppInitializerProvider,
    RefreshGridNotification,
    PermissionService,
    DownloadService,
    FileService,
    ViewContainerRefService,
    GraphqlService,
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
  ],
})
export class AppModule {}
